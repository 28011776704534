import {DialogController} from "aurelia-dialog";
import {inject} from "aurelia-framework";
import {Client} from '../api/client';
import {FlashService} from "../flash/flash-service";

import "./insurance-api-choice.less";

@inject(DialogController, Client, FlashService)
export class InsuranceApiChoice {
    constructor(dialogController, client, flash) {
        this.dialogController = dialogController;
        this.client = client;
        this.flash = flash;
    }

    async activate(context) {
        this.data = context.data;
        this.title = context.title;

        try {
            const response = await this.client.post('tourism-insurance-api/backend-offers', {
                participants: this.data.participants
            });

            this.offers = response.data.offers;
            this.request = response.data.request;
            this.warnings = response.data.warnings;
            this.selected = null;
            this.additional = [];

            const providers = new Set(response.data.offers.map(offer => offer.provider));

            this.multiple = 1 < providers.size;

        } catch (e) {
            this.message = e.data?.localizedMessage ?? e.data?.message ?? "Unbekannter Fehler";
        }
    }

    select() {
        if (!this.selected) {
            this.dialogController.cancel();
        }

        this.data.data = Object.assign({}, this.data.data, {
            id: this.selected.id,
            provider: this.selected.provider,
            additional: this.additional.map(({id, provider}) => ({id, provider}))
        });

        this.dialogController.ok(this.data);
    }
}
